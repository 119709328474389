<template>
  <div class="d-flex justify-content-between align-items-center flex-wrap mb-75 p-1">
    <div class="d-flex align-items-center mb-0">
      <span class="text-nowrap text-black ml-1">
        {{ $t('general.show_from') }}
      </span>
      <b-form-select
        v-model="query_params.page_size"
        :options="pageSizeOptions"
        class="mx-1 text-black"
        @change="changePerPage"
      />
      <span class="text-nowrap text-black"> {{ $t('general.show_to') }} </span>
    </div>
    <div class="mb-1">
      <b-pagination
        v-model="query_params.page"
        :total-rows="totalRows"
        :per-page="query_params.page_size"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @change="changePage"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { BPagination, BFormSelect } from 'bootstrap-vue'

export default {
  components: {
    BPagination,
    BFormSelect,
  },
  props: {
    totalRows: {
      type: Number,
      required: false,
      default: 0,
    },
    pageSizeOptions: {
      type: Array,
      required: false,
      default() {
        return [5, 10, 15]
      },
    },
    queryParams: {
      type: Object,
      required: false,
      default() {
        return {
          page: 1,
          page_size: 10,
        }
      },
    },
  },
  data() {
    return {
      query_params: {
        page: 1,
        page_size: 10,
      },
    }
  },
  watch: {
    queryParams() {
      this.query_params = this.queryParams
    },
  },
  methods: {
    changePage(val) {
      this.$emit('changePage', val)
    },
    changePerPage(val) {
      this.$emit('changePerPage', val)
    },
  },
}
</script>

<style>

</style>
