var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"title":_vm.editData.id? _vm.$t(("" + _vm.editTitle)) : _vm.$t(("" + _vm.addTitle)),"size":"sm","ok-variant":"success","cancel-variant":"danger","ok-title":_vm.$t(_vm.okTitle),"cancel-title":_vm.$t(_vm.cancelTitle),"centered":"","no-close-on-backdrop":""},on:{"show":_vm.openModal,"ok":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)},"cancel":function($event){return _vm.$bvModal.hide(("" + _vm.id))}}},[_c('validation-observer',{ref:"validation"},[(!_vm.noLang)?_c('b-tabs',{attrs:{"content-class":"mt-2","justified":""}},[_c('b-tab',{attrs:{"active":"","title":"RU"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Название'),"label-for":"title_input_ru"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Название"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title_input_ru","placeholder":_vm.$t('Название'),"state":errors.length > 0 ? false : null},model:{value:(_vm.data.title.ru),callback:function ($$v) {_vm.$set(_vm.data.title, "ru", $$v)},expression:"data.title.ru"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,674251447)})],1)],1),_c('b-tab',{attrs:{"title":"EN"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Title'),"label-for":"title_input_en"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title_input_en","placeholder":_vm.$t('Title'),"state":errors.length > 0 ? false : null},model:{value:(_vm.data.title.en),callback:function ($$v) {_vm.$set(_vm.data.title, "en", $$v)},expression:"data.title.en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1161219519)})],1)],1),_c('b-tab',{attrs:{"title":"UZ"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Nomi'),"label-for":"title_input_uz"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nomi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title_input_uz","placeholder":_vm.$t('Nomi'),"state":errors.length > 0 ? false : null},model:{value:(_vm.data.title.uz),callback:function ($$v) {_vm.$set(_vm.data.title, "uz", $$v)},expression:"data.title.uz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2098894746)})],1)],1)],1):[_c('b-form-group',{attrs:{"label":_vm.$t('general.title'),"label-for":"title_input_ru"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('general.title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1 ",attrs:{"id":"title_input_ru","placeholder":_vm.$t('general.title'),"state":errors.length > 0 ? false : null},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.branch)?[(_vm.$can('manage', 'super_admin') || _vm.$can('manage', 'head_manager'))?_c('b-form-group',{attrs:{"label":_vm.$t('others.branch'),"label-for":"role"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('others.branch')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"role","label":"title","options":_vm.GET_APPROVED_BRANCH.results,"placeholder":_vm.$t('others.branch'),"reduce":function (user) { return user.id; }},model:{value:(_vm.data.branch_id),callback:function ($$v) {_vm.$set(_vm.data, "branch_id", $$v)},expression:"data.branch_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2661640481)})],1):_vm._e()]:_vm._e(),(_vm.description)?_c('b-form-group',{attrs:{"label":_vm.$t('general.description'),"label-for":"desc"}},[_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('general.description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"desc","placeholder":_vm.$t('general.description'),"rows":"3","max-rows":"8"},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1618217365)})],1):_vm._e()],(_vm.isCourse)?[_c('b-form-group',{attrs:{"label":_vm.$t('courses.price'),"label-for":"price"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('courses.price')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","placeholder":_vm.$t('courses.price'),"state":errors.length > 0 ? false : null},model:{value:(_vm.data.price),callback:function ($$v) {_vm.$set(_vm.data, "price", $$v)},expression:"data.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3543443850)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('courses.subjects'),"label-for":"subject"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('courses.subjects')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"subject","get-option-label":function (el) {
              return el.title[_vm.$i18n.locale]
            },"options":_vm.GET_SUBJECTS_LIST.results,"reduce":function (el) { return el.id; },"placeholder":_vm.$t('courses.subjects'),"state":errors.length > 0 ? false : null},model:{value:(_vm.data.subject),callback:function ($$v) {_vm.$set(_vm.data, "subject", $$v)},expression:"data.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2385723464)})],1)]:_vm._e(),[_vm._t("default")]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }